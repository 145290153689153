import { useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<nav className={`navCss ${location.pathname !== "/" && "navGradient"}`}>
			<img
				className="navLogo1"
				src="/images/logos/radhamani logo.png"
				onClick={() => navigate("/")}
				style={{ cursor: "pointer" }}
			/>
		</nav>
	);
};

export default Navbar;
