import { useState } from "react";
import "./Policy.css";
import axios from "axios";
const Policy = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [policy, showPolicy] = useState(false);

	const onSubmit = () => {
		axios
			.post("/api/website/verify", { email, password })
			.then((res) => {
				if (res.status === 200) {
					console.log("logged in");
					showPolicy(true);
				} else {
					alert("Invalid username or password");
				}
			})
			.catch((err) => {console.log(err); alert("Invalid username or password")});
	};

	return (
		<div>
			{!policy ? (
				<div class="background-image">
					<div class="login-container">
						<h2 class="text-center mb-4 text">LOG IN TO YOUR ACCOUNT</h2>
						<form class="form">
							<div class="form-floating mb-3">
								<input
									type="email"
									class="form-control"
									id="floatingInput"
									placeholder="name@example.com"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<label for="floatingInput">Email</label>
							</div>
							<div class="form-floating">
								<input
									type="password"
									class="form-control"
									id="floatingPassword"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<label for="floatingPassword">Password</label>
							</div>
							<div type="submit" class="login btn btn-light" onClick={onSubmit}>
								LOG IN
							</div>
						</form>
					</div>
				</div>
			) : (
				<>
					<header style={{ padding: 20 }}>
						<div style={{ height: 200 }}></div>
						<h2>Prevention to Sexual Harassment Policy (POSH)</h2>
						<h4>OBJECTIVE :</h4>
						<p>
							The company firmly believes that every employee has a right to work in an environment free
							from harassing, intimidating or ofensive behaviour and which issues of harassment will be
							resolved without fear or reprisal. The POSH Policy is designed to take effective measures to
							avoid and to eliminate and if necessary to impose punishment or any sexual harassment
						</p>
						<h4>SCOPE :</h4>
						<p>
							This policy is applicable for all categories of employees of the company, including
							permanent management and workmen, Employees, trainees, temporaries, apprentices,
							consultants, employed through contractors, contract labours etc., at its
							workspace/stores/oces. The workspace includes:{" "}
						</p>
						<br />
						•All oces/stores or other premises where the Company’s business is conducted. <br />
						•All company related activities performed at any other site away from the Company’s
						premises/stores. <br />
						•Any social,business or other functions where the conduct or comments any have an adverse impact
						on the workspace or workspace relations. Provided that nothing in this policy shall apply to any
						action between any independent contractor and his/her employees or among such employees, or any
						action between contract labour of two independent contractor who are in the utilisation of the
						company as contract labour or such cases will not come under preview of this policy.
						<h4>POLICY : </h4>
						Harassment in any form will not be permitted for tolerated of condoned by the company whether it
						is based on a person`s race, color, ethnicity or nationality, gender,real or suspected sexual
						orientation, religion or perceived religious aliation, disability or other person`s
						characteristics. The use of company property including email, bulletin board, documents or any
						communication platform as a mode of harassment is prohibited. DEFINITION : a) “Company” means
						M/s Radhamani Textiles Pvt. Ltd. b) "Board" means the Board of Directors of the Company. c)
						"Chairperson" means Chairperson of the Grievance Redressal Committee appointed under this
						Policy. d) "Company" means its branches, units and sister concerns including Sales and Marketing
						division. e) "Employee" Shall mean and include all employees, Contract Labour in the utilization
						of the Company, apprentices, trainees, all ocers and consultants of the Company. f) "Grievance
						Redressal Committee" means Grievance Redressal Committee constituted under this rules. g)
						"Policy" means this Policy i.e., the Policy of Prohibiting for Sexual Harassment at work place.
						<h4>PROHIBITION OF SEXUAL HARASSMENT :</h4>
						No employee shall engage in any sexual harassment. Meaning of Sexual Harassment & Sexual
						Oriented Behaviour 'Sexual Harassment', includes any unwelcome Sexually determined behavior,
						direct or by implication, and includes physical contact and advances, a demand or request for
						Sexual favours, Sexually coloured remarks, showing Pornography or any other unwelcome physical,
						verbal or non verbal conduct of a Sexual Nature. Sexually oriented behaviour shall mean and
						include but not limited to the following: <br />
						•Material that is Sexual in nature, sexiest, sexually explicit and so on and is displayed in the
						work place, circulated, or put in someone's workspace or belongings, or on a computer or fax
						machine or on the Internet or any other public display system or public place in the work
						premises <br />
						•Verbal abuse or comments that put down people because of their sex <br />
						•Comment about people's (women/men) bodies Tales of Sexual exploits <br />
						•Graphic descriptions of pornography <br />
						•Pressure for dates <br />
						•Sexually explicit gestures <br />
						•Unwelcome touching and hugging
						<br />
						•Sexiest and insulting grati <br />
						•Sexist jokes and cartoons <br />
						•Obscene phone calls <br />
						•Displaying pornography in the workplace <br />
						•Insisting that workers wear revealing clothing <br />
						•Inappropriate gifts (for example, lingerie)
						<br />
						•Discussion of one's partner's sexual inadequacies <br />
						•Lewd and threatening letters <br />
						•"Accidentally" brushing sexual parts of the body <br />
						•Pressing or rubbing up against a victim <br />
						•Sexual sneak attacks (such as grabbing private parts on the run) <br />
						•Indecent exposure <br />
						•Subtle or overt pressure for sexual favours <br />
						•Soliciting sexual services <br />
						•Demanding sexual services <br />
						•Sexual or Physical contact, such as slapping, kissing or touching
						<br />
						•Intrusive questions about sexual activity <br />
						•Sexual assault <br />
						•Repeated sexual invitations when the person invited has refused/ignored similar invitations{" "}
						<br />
						•Coerced sexual
						<h4>HARASSMENT :</h4>
						intercourse (e.g. as a condition of the employment or academic status) DOES NOT MEAN SEXUAL
						Sexual Harassment should not be confused with simple friendly behaviour or with more intimate
						exchanges, if these are mutually desires and accepted. The dierence between friendly behaviour
						and sexual harassment is that Sexual Harassment is neither solicited nor accepted by the
						recipient, it is unwelcome and/or imposed.
						<h4>RIGHTS & RESPONSIBILITIES OF EMPLOYEES :</h4>
						•Every Employee shall be entitled to a work environment free from Sexual Harassment. <br />
						•Every Employee shall be entitled to complain against Sexually Oriented Behaviour to which
						he/she was subjected to or which was targeted at him/her, to the Grievance Redressal Committee
						or to the Chairperson or any other Member of the Grievance Redressal Committee established under
						these Rules, as the case may be.
						<h4>CONSTITUTION OF THE GRIEVANCE REDRESSAL COMMITTEE: </h4>
						•The Board shall establish a Grievance Redressal Committee to deal with Sexual harassment. The
						Grievance Redressal Committee shall consist of five or more members at any time. One member will
						be common and other members will be changing from those respective unit. <br />
						•One Female member to act as Chairperson of the Grievance Redressal Committee, one male member
						and other three members will be female at each unit. The term of the oce Grievance Redressal
						Committee is for a period of two years and after the expiry of the said period the Company shall
						reconstitute the Grievance Redressal Committee. <br />
						•The Chairperson of the committee is the Group Welfare Ocer of the Company. <br />
						•The names of the persons who are on the Grievance Redressal Committee from time to time along
						with their contact telephone numbers and other details shall be displayed on the notice board of
						the company.
						<h4>POWER AND DUTIES OF THE GRIEVANCES REDRESSAL COMMITTEE : </h4>
						•To ensure implementation of this policy. <br />• To ensure and supervise proper constitution
						and functioning of the Grievance Redressal Committee. <br />
						•To organize regular workshop and training programmes. <br />
						•To formulate programmes for the spread of awareness of the policy among the management. <br />
						•To bring out publication concerning Sexual harassment and also concerning implementation of
						this policy.
						<h4> GRIEVANCE HANDLING PROCEDURE: </h4>
						•Any employee will have a right to lodge a complaint concerning sexual harassment against on
						employee or outsider with Grievance Redressal Committee. <br />
						•Such a complaint may be oral or in writing. <br />
						•If the Complaint is oral, the same shall be reduced in writing in the detail by the Grievance
						Redressal Committee member to whom the complaint is made. <br />
						•The complainant will be aorded full confidentiality at this stage. <br />
						•Immediately upon receipt of the Complaint, and within not more than 2 working days, the Member
						of the Grievances Redressal Committee to whom the Complaint is made shall communicate the same
						to the Chairperson of the Grievance Redressal Committee. <br />
						•Within a period of 5 working days from the date of such communication, the Chairperson shall
						convene a meeting of the Grievance Redressal Committee. <br />
						•The Grievance Redressal Committee shall examine the complaint and shall undertake investigation
						of the complaint after giving opportunities to the complainant to present his case and the
						accused to give his version. The Grievance Redressal Committee may examine witness from both the
						sides and the other side will have opportunity of cross-examine the witness. Documents if any
						produced but the parties may be taken on record. The Grievance Redressal
						<br />
						•Committee after completion of investigation may submit its report to the HRD for further
						necessary action in the matter.
						<h4> PROTECTION AGAINST VICTIMISATION :</h4>
						In the event complainant being an employee and the accused being his / her manager, during the
						pendency of investigation and the competent authority may impose any of the punishment and
						envisaged in the policy on disciplinary process. CONSEQUENCE OF COMPLAINT BEING
						<h4>PROVED :</h4>
						In the event allegations made in the complaint are proved against the employee, it will be taken
						as proved misconduct and the competent authority may impose any of the punishment as envisaged
						in the policy on disciplinary process.
						<h4> OBLIGATION OF THE MANAGEMENT : </h4>
						The Management of the Company shall provide all necessary assistance for the purpose of ensuring
						full eective and speedy implementation of this policy. Management shall be bound by the decision
						of the Grievance Redressal Committee constituted as above and shall implement such decisions in
						and expeditious manner. "Punishment for false or malicious complaint and false evidence" Where
						the Grievance Redressal Committee arrives at the conclusion that the allegations against the
						accused is false or malicious or the aggrieved woman or any other person making complaint has
						produced any forged or misleading document, the Committee may recommend the Company to take
						action against the woman or the other person who had made the complaint, in accordance with the
						provisions of the services rules applicable to her or him. THIRD PARTY HARASSMENT : In case of
						third party Sexual Harassment the Grievance Redressal Committee will actively assist and provide
						all its resources to the complainant in pursuing the complainant in pursuing the complaint and
						ensure his/her safety at least in the company's premises. With the sole intention of providing
						justice to the aggrieved.
						<h4>SUB JUDICE :</h4> The proceedings under this policy shall not be stalled or postponed merely
						because the complainant is proceeding against the accused under any other provision or civil or
						criminal law. The co-committee must come out with its findings at the earliest. The Provision of
						this policy shall not restrict the power of company or complainant to proceed against the
						alleged oender for any other misconduct or to pursue the criminal or civil remedies.
					</header>
					;
				</>
			)}
		</div>
	);
};

export default Policy;
