import { useState } from "react";
import axios from "axios";

import "./ContactUs.css";

const ContactUs = () => {
	const initial_state = {
		name: "",
		email: "",
		contact: "",
		message: "",
		query: "",
		region: "",
		others: "",
	};
	const [contactUs, setContactUs] = useState(initial_state);
	const [others, setOthers] = useState(false);
	const [submitDisable, setDisable] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setDisable(true);
		await axios
			.post("/api/website/contact_us", contactUs)
			.then((res) => {
				if (res.status === 200) {
					alert("Form submitted successfully");
					setContactUs(initial_state);
					setDisable(false);
				}
			})
			.catch((err) => {
				alert("Something went wrong");
				setDisable(false);
			});
	};

	const handleQueries = (e) => {
		if (e.target.value === "others") {
			setOthers(true);
		} else {
			setOthers(false);
		}
		setContactUs({ ...contactUs, query: e.target.value });
	};

	return (
		<div className="container-fluid">
			<div style={{ height: 230 }}></div>
			<h2 className="p-3 text-center">Contact Us</h2>
			<form onSubmit={handleSubmit}>
				<div className="row" style={{ display: "flex" }}>
					<div style={{ margin: "20px 0", width: "25%" }}>
						<label htmlFor="query" className="form-label">
							Query<span>*</span>
						</label>
						<select
							class="form-select"
							id="query"
							aria-label="Default select example"
							value={contactUs?.query}
							required
							onChange={handleQueries}>
							<option value="">select query </option>
							<option value="trader">Trade</option>
							<option value="recruitment">Recruitment</option>
							<option value="others">Others</option>
						</select>
					</div>
					{contactUs.query === "trader" ? (
						<div style={{ margin: "20px", width: "25%", paddingLeft: "7px" }}>
							<label htmlFor="query_type" className="form-label">
								Region<span>*</span>
							</label>
							<select
								class="form-select"
								id="query_type"
								aria-label="Default select example"
								required
								value={contactUs?.region}
								onChange={(e) => setContactUs({ ...contactUs, region: e.target.value })}>
								<option selected>select query type</option>
								<option value="india">India</option>
								<option value="international">International</option>
							</select>
						</div>
					) : others ? (
						<div style={{ margin: "20px", width: "25%" }}>
							<label htmlFor="exampleInputEmail1" className="form-label">
								Title<span>*</span>
							</label>
							<input
								value={contactUs.others}
								required
								type="text"
								className="form-control"
								aria-describedby="nameHelp"
								onChange={(e) => setContactUs({ ...contactUs, others: e.target.value })}
							/>
						</div>
					) : (
						""
					)}
				</div>
				<div className="row">
					<div className="col-md-4 p-2">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Name<span>*</span>
						</label>
						<input
							value={contactUs.name}
							required
							type="text"
							className="form-control"
							aria-describedby="nameHelp"
							onChange={(e) => setContactUs({ ...contactUs, name: e.target.value })}
						/>
					</div>
					<div class="toast-container position-fixed top-0 end-0 p-3"></div>
					<div className="col-md-4 p-2">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Email address<span>*</span>
						</label>
						<input
							value={contactUs.email}
							required
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setContactUs({ ...contactUs, email: e.target.value })}
						/>
					</div>

					<div className="col-md-4 p-2">
						<label htmlFor="exampleInputContact" className="form-label">
							Contact Number<span>*</span>
						</label>
						<input
							value={contactUs.contact}
							required
							type="number"
							className="form-control"
							id="exampleInputContact"
							aria-describedby="contactHelp"
							onChange={(e) => setContactUs({ ...contactUs, contact: e.target.value })}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 p-2">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							Your Message<span>*</span>
						</label>
						<textarea
							value={contactUs.message}
							required
							className="form-control"
							style={{ width: "50%" }}
							id="exampleFormControlTextarea1"
							rows="4"
							cols={53}
							onChange={(e) => setContactUs({ ...contactUs, message: e.target.value })}></textarea>
					</div>
				</div>
				<div className="form-group text-center">
					<button
						type="submit"
						className="btn m-3"
						style={{ background: "black", color: "white" }}
						disabled={submitDisable}>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default ContactUs;
