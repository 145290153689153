import HomePage from "components/HomePage/HomePage";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import JoinUs from "components/JoinUs/JoinUs";
import Policy from "components/Policy/Policy";
import Navbar from "components/Navbar/NavBar";
import Footer from "components/Footer/Footer";
import ContactUs from "components/ContactUs/ContactUs";
import axios from "axios";

function App() {
	axios.defaults.baseURL = "https://api.thehouseofrare.com";
	return (
		<div className="App">
			<Navbar />
			<header className="App-header">
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="joinUs" element={<JoinUs />} />
					<Route path="contactUs" element={<ContactUs />} />
					<Route path="policy" element={<Policy />} />
				</Routes>
			</header>
			<Footer />
		</div>
	);
}

export default App;
