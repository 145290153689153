import { useState } from "react";
import "./JoinUs.css";
import { getDatabase, push, ref, set } from "firebase/database";
import database from "utils/firebaseConfig";
import axios from "axios";

const JoinUs = () => {
	// const database = getDatabase();
	const initial_state = {
		name: "",
		email: "",
		contact: "",
		currentLocation: "",
		preferredLocation: "",
		education: "",
		gender: "Select",
		department: "Select",
		experience: "",
		currentCtc: "",
		expectedCtc: "",
		notice: "Select",
		linkedinLink: "",
		resumelink: "",
	};
	const [file, setFile] = useState();
	const [formData, setFormData] = useState(initial_state);
	const [submitDisable, setDisable] = useState(false);

	const GENDER = [
		{
			label: "Select",
			value: "SELECT",
		},
		{
			label: "Male",
			value: "MALE",
		},
		{
			label: "Female",
			value: "FEMALE",
		},
		{
			label: "Others",
			value: "OTHERS",
		},
	];

	const DEPARTMENT = [
		{
			label: "Select",
			value: "Select",
		},

		{
			value: "Sales & Operations (Offline)",
			label: "Sales & Operations (Offline)",
		},
		{
			value: "Sales & Operations (Online)",
			label: "Sales & Operations (Online)",
		},
		{
			value: "Design and Product Development",
			label: "Design and Product Development",
		},
		{ value: "Purchase and Procurement", label: "Purchase and Procurement" },
		{ value: "Projects and Maintenance", label: "Projects and Maintenance" },
		{ value: "HR & Admin", label: "HR & Admin" },
		{ value: "Finance and Accounts", label: "Finance and Accounts" },
		{ value: "Business Development", label: "Business Development" },
		{ value: "Technology-IT", label: "Technology-IT" },
		{ value: "Buying and Planning", label: "Buying and Planning" },
		{
			value: "Sourcing and Merchandising",
			label: "Sourcing and Merchandising",
		},
		{ value: "Product Quality", label: "Product Quality" },
		{ value: "Warehouse Operations", label: "Warehouse Operations" },
		{ value: "Studio and Art Direction", label: "Studio and Art Direction" },
		{
			value: "Communication/ Graphic Design",
			label: "Communication/ Graphic Design",
		},
		{
			value: "Brand Marketing/PR/Influencer Marketing/Performance Marketing",
			label: "Brand Marketing/PR/Influencer Marketing/Performance Marketing",
		},
		{ value: "Sales Associate", label: "Sales Associate" },
		{ value: "Store Manager", label: "Store Manager" },
	];

	const NOTICE = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "30 days",
			value: "30 days",
		},
		{
			label: "60 days",
			value: "60 days",
		},
		{
			label: "90 days",
			value: "90 days",
		},
	];

	const validateForm = () => {
		let { gender, notice, department } = formData;
		if (gender === "Select" || department === "Select" || notice === "Select") {
			alert("Please select all dropdowns");
			return true;
		}
		return false;
	};
	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		setFile(newFile);
	};

	// handle resume upload htmlFor join us form
	const handleFileUpload = async () => {
		let form = new FormData();
		form.append("files", file);
		form.append("contact", formData.contact);
		form.append("name", formData.name);
		return await axios
			.post("/api/website/resume-upload", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				const response = { ...formData, resumelink: res.data[0].publicUrl, status: res.status }
				setFormData({ ...formData, resumelink: res.data[0].publicUrl });
				return response;
			})
			.catch((err) => {
				return false;
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setDisable(true)
		if (validateForm()) {
			return;
		}
		const res = await handleFileUpload();
		if (res.status === 200) {
			axios
				.post("api/website/join_us", res)
				.then((res) => {
					if (res.status === 200) {
						alert("Form Submitted Successfully");
						setFormData(initial_state)
						document.getElementById("file").value = "";
						setDisable(false)
					}
				})
				.catch((err) => {
					alert("Something went wrong");
					setDisable(false)
				});
		} else {
			alert("please try again");
			setDisable(false)
		}
	};

	const preventMinus = (e) => {
		if (e.code === 'Minus' || e.key === '+') {
			e.preventDefault();
		}
	};

	return (
		<div className="container-fluid " style={{ width: "100%" }}>
			<div style={{ height: 230 }}></div>
			<h2 className="p-3 text-center">Job Application Form</h2>
			<form onSubmit={handleSubmit}>
				<div className="row justify-content-center">
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputName" className="form-label">
							Name
						</label>
						<input
							required
							value={formData.name}
							type="text"
							className="form-control"
							aria-describedby="nameHelp"
							onChange={(e) => setFormData({ ...formData, name: e.target.value })}
						/>
					</div>

					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail" className="form-label">
							Email address
						</label>
						<input
							required
							value={formData.email}
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
						/>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputContact" className="form-label">
							Contact Number
						</label>
						<input
							required
							value={formData.contact}
							min={0}
							type="number"
							className="form-control"
							id="exampleInputContact"
							aria-describedby="contactHelp"
							onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
						/>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputLocation" className="form-label">
							Current location
						</label>
						<input
							required
							value={formData.currentLocation}
							type="text"
							className="form-control"
							aria-describedby="CurrentLocationHelp"
							onChange={(e) => setFormData({ ...formData, currentLocation: e.target.value })}
						/>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputlocation" className="form-label">
							Preferred location
						</label>
						<input
							required
							value={formData.preferredLocation}
							type="text"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="PreferredLocation"
							onChange={(e) => setFormData({ ...formData, preferredLocation: e.target.value })}
						/>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Educational Qualification
						</label>
						<input
							value={formData.education}
							required
							type="text"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setFormData({ ...formData, education: e.target.value })}
						/>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Gender
						</label>
						<select
							className="form-select"
							aria-label="Default select example"
							onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
							required
							value={formData.gender}>
							{GENDER.map((gen) => (
								<option key={gen.value} value={gen.value}>
									{gen.label}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Applied Department
						</label>
						<select
							className="form-select"
							required
							onChange={(e) => setFormData({ ...formData, department: e.target.value })}
							value={formData.department}>
							{DEPARTMENT.map((dep) => (
								<option key={dep.value} value={dep.value}>
									{dep.label}
								</option>
							))}
						</select>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Experience (in years)
						</label>
						<input
							value={formData.experience}
							required
							min={0}
							onKeyPress={preventMinus}
							type="number"
							className="form-control"
							aria-describedby="ExperienceHelp"
							onChange={(e) => setFormData({ ...formData, experience: e.target.value })}
						/>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Current CTC (per annum INR)
						</label>
						<input
							required
							value={formData.currentCtc}
							min={0}
							type="number"
							onKeyPress={preventMinus}
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setFormData({ ...formData, currentCtc: e.target.value })}
						/>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Expected CTC (per annum INR)
						</label>
						<input
							required
							value={formData.expectedCtc}
							type="number"
							min={0}
							onKeyPress={preventMinus}
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setFormData({ ...formData, expectedCtc: e.target.value })}
						/>
					</div>
					<div className="col-md-4 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Notice period (in days)
						</label>
						<select
							className="form-select"
							aria-label="Default select example"
							required
							onChange={(e) => setFormData({ ...formData, notice: e.target.value })}
							value={formData.notice}>
							{NOTICE.map((notice) => (
								<option key={notice.value} value={notice.value}>
									{notice.label}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-md-6 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Resume
						</label>
						<input accept="application/pdf" required id='file' type="file" className="form-control" onChange={handleFileChange} />
					</div>
					<div className="col-md-6 col-sm-6 my-2 ">
						<label htmlFor="exampleInputEmail1" className="form-label">
							Linkedin profile (Link)
						</label>
						<input
							required
							value={formData.linkedinLink}
							type="url"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							onChange={(e) => setFormData({ ...formData, linkedinLink: e.target.value })}
						/>
					</div>
				</div>
				<div className="form-group text-center m-3">
					<button type="submit" className="btn" style={{ background: "black", color: "white" }} disabled={submitDisable}>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default JoinUs;
