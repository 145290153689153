import { useNavigate } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
	const navigate = useNavigate();
	const thorContent = [
		"FASTEST GROWING INDIAN SMARTWEAR BUSINESS BUILT ON STRONG DIFFERENTIATION ",
		"UNPARALLELED CUSTOMER ADMIRATION WITH CULT DEVELOPING AROUND THE BRAND(S)",
		"STRONG PLATFORM BUILT ACROSS ALL ASPECTS OF THE BUSINESS",
		"ENORMOUS MARKET WITH STRONG TAILWINDS",
	];
	const goToUrl = (url) => {
		document.documentElement.scrollTop = 0;
		navigate(url);
	};
	return (
		<div className="HomePage">
			<div class="imgContainer">
				<img loading="lazy" class="bgImg mobileOff" src="/images/about_us_new.png" alt="factory_img" />
				<img loading="lazy" class="bgImg mobileOn" src="/images/about_us_mobile.png" alt="factory_img" />
				{/* <h1 className="aboutUsHead centuryGothic">ABOUT US</h1>
				<h4 className="imgInfo centuryGothic">
					RADHAMANI FACTORY <br /> ACTUAL IMAGES
				</h4> */}
			</div>
			<div class="imgContainer ">
				<img class="bgImg mobileOff" src="/images/store_new.png" alt="factory_img" />
				<img class="bgImg mobileOn" src="/images/store_mobile.png" alt="factory_img" />
				{/* <img loading="lazy" class="thorLogo" src="/images/logos/THOR logo.png" alt="factory_img" /> */}
				{/* <div className="storeInfo">
					<h4 className="storeText centuryGothic">
						<span style={{ fontSize: 50, fontWeight: 700 }}>175+</span> EXCLUSIVE STORES
					</h4>
					<h4 className="storeText centuryGothic">
						<span style={{ fontSize: 50, fontWeight: 700 }}>700+</span> POINT OF SALES
					</h4>
				</div>
				<h4 className="imgInfo greyColor centuryGothic">JAIPUR STORE</h4> */}
			</div>
			<div className="brands">
				<div
					className="rareRabbit"
					onClick={() => (window.location.href = "https://thehouseofrare.com/pages/rare-rabbit")}>
					<img loading="lazy" className="rrPhoto cursor" src="/images/rr_fluer_cropped.jpg" alt="rarerabbit" />
					<img
						loading="lazy"
						className="rrLogo cursor"
						src="/images/logos/rare rabbit logo.png"
						alt="rarerabbitLogo"
					/>
				</div>
				<div className="rareism" onClick={() => (window.location.href = "https://www.rareism.com/")}>
					<img loading="lazy" className="rsmPhoto cursor" src="/images/rareism fluer shoot.jpg" />
					<img loading="lazy" className="rsmLogo cursor" src="/images/logos/rareism logo.png" alt="rarerabbitLogo" />
				</div>
			</div>
			<div className="imgContainer">
				<img class="bgImg mobileOff" src="/images/thor_new.png" alt="factory_img" />
				<img class="bgImg mobileOn" src="/images/thor_mobile.png" alt="factory_img" />
				{/* <div className="thorContentDummy" />
				<div className="thorContent">
					{thorContent &&
						thorContent.map((text) => {
							return <p className="thorText centuryGothic">{text}</p>;
						})}
				</div>
				<h4 className="imgInfo greyColor centuryGothic">
					CAMPAIGN THE WALK
					<br />
				</h4> */}
			</div>
			<div className="brands">
				<div className="policy cursor" onClick={() => goToUrl("/policy")}>
					<img loading="lazy" className="policyPhoto" src="images/policies_new.png" alt="rarerabbit" />
					{/* <h6 className="policyText centuryGothic">POLICIES</h6> */}
				</div>
				<div className="policy">
					<img loading="lazy" className="policyPhoto" src="/images/CSR_new.png" />
				</div>
			</div>
			<div className="" onClick={() => goToUrl("/joinUs")}>
				<img className="bgImg1 mobileOff cursor" src="/images/join_us_new.png" alt="factory_img" />
				<img className="bgImg1 mobileOn cursor" src="/images/join_us_mobile.png" alt="factory_img" />
				{/* <div className="joinUs">
					<h4 className=" joinUsHead centuryGothic">JOIN US</h4>
					<p className="joinUsText centuryGothic">BE A PART OF THE RARE WORLD</p>
				</div>
				<h4 className="imgInfo greyColor centuryGothic">
					CAMPAIGN THE WALK
					<br />
				</h4> */}
			</div>
		</div>
	);
};

export default HomePage;
