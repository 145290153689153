import { useNavigate } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
	const navigate = useNavigate();
	const goToUrl = (url) => {
		document.documentElement.scrollTop = 0;
		navigate(url);
	};
	return (
		<footer className="footer1">
			<div className="footerContents">
				<h6 className="centuryGothic footerEle1" onClick={() => goToUrl("/")}>
					ABOUT US
				</h6>
				<h6 className="centuryGothic footerEle1" onClick={() => goToUrl("/contactUs")}>
					CONTACT US
				</h6>
				<h6 className="centuryGothic footerEle1" onClick={() => goToUrl("/joinUs")}>
					JOIN US
				</h6>
			</div>
			<h6 className="centuryGothic footerEle1">ALL RIGHTS RESERVED</h6>
			<p className="centuryGothic footerCopyRights1"> © Developed and mantained by Radhamani Textiles Pvt Ltd.</p>
		</footer>
	);
};

export default Footer;
